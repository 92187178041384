import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import hero1 from '../images/volunteer/hero1.webp'
import hero2 from '../images/volunteer/hero2.webp'

const VolunteerPage = () => (
  <Layout>
    <Seo title="Volunteer" />

    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-5 px-4 xl:px-0 sm:py-5">
        <div>
          <img
              className="w-full max-h-34-rem object-cover object-pos-0-87" // TODO: figure out aspect ratio -- trying to set 2:1
              src={hero1}
              alt=""
            />
        </div>

        <div className="text-center">
          <p className="pt-5 pb-5 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            Volunteer
          </p>
        </div>

        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfZ4gpWslm18qH-V74uq3TVeGG3UUyQNAFlG1pt6ANO_LE_rQ/viewform?embedded=true" width="100%" height="1495" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
      
        {/* TODO: try left col. */}
        <div>
          <img
              className="w-full" // TODO: figure out aspect ratio -- trying to set 2:1
              src={hero2}
              alt=""
            />
        </div>
      </div>
    </div>

    <div className="relative bg-gray-50 pb-10 px-4 sm:px-6 lg:pb-10 lg:px-8">  
      <div className="relative max-w-7xl mx-auto">

      </div>
    </div>
  </Layout>
)

export default VolunteerPage
